/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useCarHighlightLocation = (
    cityCode = ""
) => {

    const [currentCarCityInfo, setCurrentCarCityCode] = useState({});

    const {
        config
    } = useSelector((state) => state || {});

    const {
        hubLocations
    } = config || {};

    useEffect(() => {
        const isCarHubPresent = Object.keys(hubLocations || {}).find((v) => v === cityCode);
        if (isCarHubPresent) {
            const carHubDetails = hubLocations[cityCode];
            setCurrentCarCityCode(carHubDetails);
        }
    }, [cityCode, hubLocations]);

    const getHubsInfo = () => {
        const value = Object.keys(hubLocations || []).map((key) => hubLocations[key]);
        return value || [];
    };

    return {currentCarCityInfo, getHubsInfo};
};

export default useCarHighlightLocation;
